import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEN from './Elements/locale/en.json';
import translationAR from './Elements/locale/ar.json';
import translationDE from './Elements/locale/de.json';
import translationFR from './Elements/locale/fr.json';
import translationIT from './Elements/locale/it.json';
import translationRU from './Elements/locale/ru.json';
import translationTR from './Elements/locale/tr.json';
import translationZH from './Elements/locale/zh.json';
import translationES from './Elements/locale/es.json';
import store from "./Store";




const resources = {
    en: {
        translation: translationEN
    },
    ar: {
        translation: translationAR
    },
    de: {
        translation: translationDE
    },
    fr: {
        translation: translationFR
    },
    it: {
        translation: translationIT
    },
    ru: {
        translation: translationRU
    },
    tr: {
        translation: translationTR
    },
    zh: {
        translation: translationZH
    },
    es: {
        translation: translationES
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: sessionStorage?.getItem("selectedLanguage") || store?.getLn()|| "de", // Ensure store.getLng() is called
        fallbackLng: "en",
        debug: true,
        useSuspense: false, // Use the new useSuspense behavior
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        react: {
            wait: true,
            useSuspense: false
        }
    });

export default i18n;
