import React from 'react';
import MainApp from './MainApp';
import TextManagement from './Elements/Pages/admin/TextManagement';



function App() {
  

    return (
        <div className="font-pluto">
            <MainApp />
        </div>
    );
}

export default App;
