import { changeLanguage, t } from 'i18next';
import * as React from 'react';
import store from './Store';

const LanguageSelectorArr = [
  { code: "de", name: "Deutsch " },
  { code: "en", name: "Englisch" },

];

export interface ILanguageSelectorProps {}

export interface ILanguageSelectorState {
  selectedLanguage: string;
}

class LanguageSelector extends React.Component<ILanguageSelectorProps, ILanguageSelectorState> {
  constructor(props: ILanguageSelectorProps) {
    super(props);
    this.state = {
      selectedLanguage:""
    };
  }

  componentDidMount(): void {
    if (this.state.selectedLanguage===""){
      this.setState({selectedLanguage:sessionStorage.getItem("selectedLanguage") || store.getLn()|| "de"});
      sessionStorage.setItem("selectedLanguage","de");
      store.setLn("de");
    }
    
  }

  public render() {
    
    return (
      <div>
        {/** add Radio button with country flag */}
        <div className="flex justify-end">
          <div className="flex items-center">
            <label className="mr-2">{t("language")}</label>
            <select
              onChange={(e) => {
                changeLanguage(e.target.value);
                store.setLn(e.target.value);
                sessionStorage.setItem("selectedLanguage",e.target.value);
                this.setState({ selectedLanguage: e.target.value });
              }}
              className="border border-gray-400 rounded-md bg-transparent"
              value={this.state.selectedLanguage}
            >
              {LanguageSelectorArr.map((item) => {
                return (
                    <option
                        className='bg-transparent'
                        key={item.code} value={item.code}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

export default LanguageSelector;
