import * as React from 'react';

export interface IButtonStylerChangerProps {
}

export interface IButtonStylerChangerState {
    buttonSize: number;
  buttonWidth: number;
  buttonHeight: number;
  buttonSpacing: number;
  buttonColor: string;
  buttonShape: 'rounded' | 'square';
  isButtonDisabled: boolean;
  isFrameVisible: boolean;
  borderColor: string;
}

export default class ButtonStylerChanger extends React.Component<IButtonStylerChangerProps, IButtonStylerChangerState> {
  constructor(props: IButtonStylerChangerProps) {
    super(props);

    this.state = {
        buttonSize: 2,
        buttonWidth: 100,
        buttonHeight: 40,
        buttonSpacing: 4,
        buttonColor: '#3498db', // Default color
        buttonShape: 'rounded',
        isButtonDisabled: false,
        isFrameVisible: true,
        borderColor: '#3498db', // Default border color
      };
    }
  
    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const target = event.target as HTMLInputElement;
      
        const newState: Partial<IButtonStylerChangerState> = {
          [target.name]:
            target.type === 'checkbox' ? target.checked : target.value,
        };
      
        this.setState(newState as Pick<IButtonStylerChangerState, keyof IButtonStylerChangerState>);
      };
      
      
  
    render() {
      const {
        buttonSize,
        buttonWidth,
        buttonHeight,
        buttonSpacing,
        buttonColor,
        buttonShape,
        isButtonDisabled,
        isFrameVisible,
        borderColor,
      } = this.state;
  
      return (
        <div className="container mx-auto mt-8 text-center">
          <h1 className="text-4xl font-bold mb-4">Button Previewer</h1>
  
          <div className="mb-4">
            <button
              className={`w-${buttonWidth} h-${buttonHeight} p-${buttonSpacing} ${
                buttonShape === 'rounded' ? 'rounded' : 'square'
              } ${isFrameVisible ? `border border-${borderColor}` : ''} ${buttonColor} text-white`}
              type="button"
              disabled={isButtonDisabled}
            >
              Button
            </button>
          </div>
  
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Button Size:</label>
            <div className="flex items-center">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-2 rounded"
                onClick={() => this.setState((prevState) => ({ buttonSize: prevState.buttonSize + 1 }))}
              >
                Increase Size
              </button>
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                onClick={() =>
                  this.setState((prevState) => ({
                    buttonSize: Math.max(prevState.buttonSize - 1, 1),
                  }))
                }
              >
                Decrease Size
              </button>
            </div>
          </div>
  
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Button Width:</label>
            <input
              type="number"
              name="buttonWidth"
              value={buttonWidth}
              onChange={this.handleChange}
              className="rounded border border-gray-300 p-1"
            />
          </div>
  
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Button Height:</label>
            <input
              type="number"
              name="buttonHeight"
              value={buttonHeight}
              onChange={this.handleChange}
              className="rounded border border-gray-300 p-1"
            />
          </div>
  
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Button Spacing:</label>
            <input
              type="number"
              name="buttonSpacing"
              value={buttonSpacing}
              onChange={this.handleChange}
              className="rounded border border-gray-300 p-1"
            />
          </div>
  
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Button Color:</label>
            <input
              type="color"
              name="buttonColor"
              value={buttonColor}
              onChange={this.handleChange}
              className="rounded border border-gray-300 p-1"
            />
          </div>
  
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Button Shape:</label>
            <select
              name="buttonShape"
              value={buttonShape}
              onChange={this.handleChange}
              className="rounded border border-gray-300 p-1"
            >
              <option value="rounded">Rounded</option>
              <option value="square">Square</option>
            </select>
          </div>
  
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Disable Button:</label>
            <input
              type="checkbox"
              name="isButtonDisabled"
              checked={isButtonDisabled}
              onChange={this.handleChange}
            />
          </div>
  
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Show Frame:</label>
            <input
              type="checkbox"
              name="isFrameVisible"
              checked={isFrameVisible}
              onChange={this.handleChange}
            />
          </div>
  
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Button Border Color:</label>
            <input
              type="color"
              name="borderColor"
              value={borderColor}
              onChange={this.handleChange}
              className="rounded border border-gray-300 p-1"
            />
          </div>
  
          <div>
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
              type="button"
            >
              Preview
            </button>
          </div>
        </div>
        );
    }
}