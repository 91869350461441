import { makeObservable,action,observable,makeAutoObservable } from'mobx';

   export 



class Store {
     adminId : string = "";
     ln:string="";
     selectedCustomerDash:string=""
     customerNames:any[]=[]
     imageCustomerPath:string=""
    constructor() {
     
         
        makeAutoObservable(this);
    }
    

    public setAdminId ( adminId : string ) {
        this.adminId = adminId;
    }
    public getAdminId () {
        return this.adminId;
    }
// seter and geter selectedCustomerDash
    public setSelectedCustomerDash ( selectedCustomerDash : string ) {
        this.selectedCustomerDash = selectedCustomerDash;
    }
    public getSelectedCustomerDash () {
        return this.selectedCustomerDash;
    }
    // seter and geter customerNames
    public setCustomerNames ( customerNames : any[] ) {
        this.customerNames = customerNames;
    }
    public getCustomerNames () {
        return this.customerNames;
    }
    public setLn ( ln : string ) {
        this.ln = ln;
    }
    public getLn () {
        return this.ln;
    }
}
const store = new Store();
export default store;