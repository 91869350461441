import * as React from "react";
import LanguageSelector from "../../../LanguageSelector";
import { WithTranslation, withTranslation } from "react-i18next";
import store from "../../../Store";

export interface CompanyInfo {
  status: string;
  data?: {
    style_id: number;
    company_id: number;
    font_size: number;
    font_color: string;
    fontType: string;
    titel: string;
    isBold: string;
    Show_link: string;
  };
}
export interface IRetourenlabelProps extends WithTranslation {
  paramName: string;
}

export interface IRetourenlabelState {
  previewImage: string;
  companyStyleinfo: CompanyInfo | null;
  refnr: string;
  name: string;
  zusatz: string;
  strasse: string;
  plz: string;
  ort: string;
  email: string;
  X: number;
  Y: number;
  decodedClientNumber: number | null;
  clientNumber: number;
  clientName: string | null;
  companyDate: {
    company_id: string;
    username: string;
    password: string;
    nummer?: string;
  };
  succesMsg: boolean;
  requiredElementData: any;
  isEmailValid: boolean;
  validationMessage: string;
  allTextsData: any;
  originalTextsData: any[];
  language: string;
  checkedCountries: {
    country_id: number;
    country_name: string;
    short_name: string;
  }[];
  land: string;
}

class Retourenlabel extends React.Component<
    IRetourenlabelProps,
    IRetourenlabelState
> {
    constructor(props: IRetourenlabelProps) {
        super(props);

        this.state = {
            previewImage: '',
            companyStyleinfo: null,
            refnr: '',
            name: '',
            zusatz: '',
            strasse: '',
            plz: '',
            ort: '',
            email: '',
            X: 62946,
            Y: 829,
            decodedClientNumber: null,
            clientNumber: 0,
            clientName: null,
            companyDate: {
                company_id: '',
                username: '',
                password: '',
                nummer: '',
            },
            succesMsg: false,
            requiredElementData: {},
            isEmailValid: true,
            validationMessage: '',
            allTextsData: {},
            originalTextsData: [],
            language: this.props.i18n.language,
            checkedCountries: [
                {
                    country_id: 0,
                    country_name: '',
                    short_name: '',
                },
            ],
            land: '',
        };
    }

    getClientName = (): void => {
        const { clientNumber } = this.state;

        const clientMap: Record<number, string> = {
            32630: 'eety',
            32632: 'Lidl Connect',
            32635: 'educom',
            32636: 'Hutchison Drei Austria',
        };

        try {
            if (clientMap.hasOwnProperty(clientNumber)) {
                this.setState({ clientName: clientMap[clientNumber] });
            } else {
                throw new Error('Kundenname nicht gefunden.');
            }
        } catch (error) {
            this.setState({ clientName: null });
        }
    };

    sendEmail = async () => {
        const requestBoody = {
            username: this.state.companyDate.username,
            password: this.state.companyDate.password,
            nummer: this.state.companyDate.nummer,
            knr: this.state.decodedClientNumber,
            refnr: this.state.refnr,
            liefernr: this.state.refnr,
            name: this.state.name,
            zusatz: this.state.zusatz,
            strasse: this.state.strasse,
            plz: this.state.plz,
            ort: this.state.ort,
            email: this.state.email,
            ausgabeformat: 'EMAIL_ANH',
            land: this.state.land,
            tel: '',
            bezperson: '',
        };

        try {
            const response = await fetch(
                'https://returnmyparcel.at/sendemail.php',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBoody),
                }
            );

            if (!response.ok) {
                throw new Error('Request failed');
            }

            const data = await response.json();
            this.setState({
                refnr: '',
                name: '',
                zusatz: '',
                strasse: '',
                plz: '',
                ort: '',
                email: '',
                succesMsg: true,
            });
            // Add your logic for handling success (e.g., show a success message)
        } catch (error) {
            console.error('Error sending email:', error);
            // Add your logic for handling errors (e.g., show an error message)
        }
    };

    getCustomerLogo(company_name: string): void {
        sessionStorage.getItem('selectedCustomerDash') !== '0'
            ? // Fetch the login API endpoint
              fetch(
                  `https://returnmyparcel.at/get_logo_api.php?company_name=${company_name}`,
                  {
                      method: 'GET',
                      headers: {
                          'Content-Type': 'application/json',
                      },
                  }
              )
                  .then(response => response.json())
                  .then(data => {
                      if (data.status === 'success') {
                          // Logo data retrieved successfully, handle further actions
                          this.setState({
                              previewImage: `data:image/png;base64,${data.data[0].image_content}`,
                          });
                      } else {
                          // Handle the case where logo data retrieval failed
                      }
                  })
                  .catch(error => {
                      // Handle network errors or other exceptions
                  })
            : this.setState({
                  previewImage: '../../../../img/logo/dpdlogo.png',
              });
    }

    extractNameFromUrl = () => {
        // Get the current URL
        const currentUrl = window.location.href;

        // Use a regular expression to extract the encoded value from the URL parameter 'm'
        const encodedValueMatch = currentUrl.match(/[?&]m=([^&]+)/);

        // Check if a match was found and return the encoded value
        const encodedValue = encodedValueMatch ? encodedValueMatch[1] : null;

        // Log the encoded value to the console

        // Return the encoded value
        return encodedValue;
    };

    getCustomerStyle(company_name: string): void {
        sessionStorage.getItem('selectedCustomerDash') !== '0'
            ? // Fetch the login API endpoint
              fetch(
                  `https://returnmyparcel.at/get_style.php?company_name=${company_name}`,
                  {
                      method: 'GET',
                      headers: {
                          'Content-Type': 'application/json',
                      },
                  }
              )
                  .then(response => response.json())
                  .then(data => {
                      if (data.status === 'success') {
                          // Logo data retrieved successfully, handle further actions
                          this.setState({ companyStyleinfo: data });
                      } else {
                      }
                  })
                  .catch(error => {
                      // Handle network errors or other exceptions
                  })
            : this.setState({ companyStyleinfo: null });
    }

    getCompanyInfo(company_name: string): void {
        // Include company_name in the URL parameters
        fetch(
            `https://returnmyparcel.at/getCompanyInfo.php?company_name=${company_name}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    // Logo data retrieved successfully, handle further actions
                    this.setState({ companyDate: data.data });
                } else {
                    // Handle the case where logo data retrieval failed
                }
            })
            .catch(error => {
                // Handle network errors or other exceptions
            });
    }

    public getRequiredElementData = async (companyName: string) => {
        const response = await fetch(
            `https://returnmyparcel.at/Required_element.php?company_name=${companyName}`
        );
        const data = await response.json();
        if (data.length > 0) {
            this.setState({ requiredElementData: data[0] });
        }
    };

    componentDidMount() {
        const companyName: string = String(this.extractNameFromUrl());
        this.getCompanyInfo(companyName);
        this.getCustomerLogo(companyName);
        this.getCustomerStyle(companyName);
        this.getRequiredElementData(companyName);
        this.getTextContentByLn();
        this.getCheckedCountries(companyName);
    }
    componentDidUpdate(
        prevProps: Readonly<IRetourenlabelProps>,
        prevState: Readonly<IRetourenlabelState>,
        snapshot?: any
    ): void {
        // prevState.companyStyleinfo?.data!==this.state.companyStyleinfo?.data
        if (this.props.i18n.language !== this.state.language)
        {
            this.setState({ language: this.props.i18n.language });
            this.getTextContentByLn();
        }

        const companyName: string = String(this.extractNameFromUrl());
        if (
            prevState.companyStyleinfo?.data?.company_id !==
            this.state.companyStyleinfo?.data?.company_id
        ) {
            this.getCustomerLogo(companyName);
            this.getCustomerStyle(companyName);
        }
        if (
            prevState.companyStyleinfo?.data?.fontType !==
            this.state.companyStyleinfo?.data?.fontType
        ) {
            this.getCustomerLogo(companyName);
            this.getCustomerStyle(companyName);
        }
        if (
            prevState.companyStyleinfo?.data?.font_size !==
            this.state.companyStyleinfo?.data?.font_size
        ) {
            this.getCustomerLogo(companyName);
            this.getCustomerStyle(companyName);
        }
        if (
            prevState.companyStyleinfo?.data?.font_color !==
            this.state.companyStyleinfo?.data?.font_color
        ) {
            this.getCustomerLogo(companyName);
            this.getCustomerStyle(companyName);
        }
        if (
            prevState.companyStyleinfo?.data?.isBold !==
            this.state.companyStyleinfo?.data?.isBold
        ) {
            this.getCustomerLogo(companyName);
            this.getCustomerStyle(companyName);
        }
        if (
            prevState.companyStyleinfo?.data?.titel !==
            this.state.companyStyleinfo?.data?.titel
        ) {
            this.getCustomerLogo(companyName);
            this.getCustomerStyle(companyName);
        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const isEmailValid = emailPattern.test(value);

        this.setState({
            email: value,
            isEmailValid,
            validationMessage: isEmailValid ? '' : 'Invalid email address',
        });
    };

    public getTextContentByLn = async () => {
        const bo = {
            action: 'gettextcontentbyln',
            language: this.props.i18n.language,
            company_name: this.extractNameFromUrl(),
        };


        try {
            const response = await fetch(
                'https://returnmyparcel.at/textmanagement.php',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(bo),
                }
            );
            const data = await response.json();
            console.log('Fetched data:', data);

            if (data.status === 'success' && data.data.length>0) {
              
                this.setState({
                    allTextsData: data.data[0], // Use the first data object if multiple
                });
            } else {
                console.error(
                    'No text data found for this company and language.'
                );
            }
        } catch (error) {
            console.error('Error fetching text content:', error);
        }
    };

    private getCheckedCountries = async (companyName: string) => {
        try {
            const response = await fetch(
                `https://returnmyparcel.at/countries_api.php?company_name=${companyName}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            const data = await response.json();
            if (data.status === 'success') {
                // Set the checkedCountries state first
                this.setState({ checkedCountries: data.data }, () => {
                    console.log(
                        'checkedCountries:',
                        this.state.checkedCountries
                    );
                    // Now that the checkedCountries are set, update the land state
                    if (this.state.checkedCountries.length === 1) {
                        this.setState({
                            land: this.state.checkedCountries[0].short_name,
                        });
                        console.log('land:', this.state.land);
                    }
                });
            } else {
                console.error('', data.message);
            }
        } catch (error) {
            console.error('', error);
        }
    };

    public render(): JSX.Element {
        const { t } = this.props;
        const { previewImage, companyStyleinfo, checkedCountries, land } =
            this.state;

        return (
            <div className="mx-0 ">
                <header className="flex shadow-md py-4 px-4 sm:px-10 bg-white font-[sans-serif] min-h-[70px] tracking-wide relative z-50">
                    <div className="flex flex-wrap items-center justify-between gap-5 w-full">
                        <img
                            src="../../../../img/logo/dpdlogo.png"
                            alt="logo"
                            className="w-36"
                        />

                        <div
                            id="collapseMenu"
                            className="max-lg:hidden lg:!block max-lg:before:fixed max-lg:before:bg-black max-lg:before:opacity-50 max-lg:before:inset-0 max-lg:before:z-50"
                        >
                            <div className="lg:flex gap-x-5 max-lg:space-y-3 max-lg:fixed max-lg:bg-white max-lg:w-1/2 max-lg:min-w-[300px] max-lg:top-0 max-lg:left-0 max-lg:p-6 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto z-50">
                                <h1 className=" font-pluto py-6 text-black block text-base font-bold">
                                    {this.state.allTextsData.header_titel || ''}
                                </h1>
                            </div>
                        </div>

                        <div className="flex max-lg:ml-auto space-x-3">
                            <LanguageSelector />
                        </div>
                    </div>
                </header>
                <section>
                    <div className="container px-6 m-auto">
                        <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
                            <div className="col-span-3">
                                <div
                                    className="flex justify-center items-center
                                    lg:justify-start lg:items-start lg:space-x-8 lg:mt-6"
                                >
                                    <img
                                        className="w-full lg:w-80 h-full  object-contain"
                                        src={previewImage}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="col-span-4 lg:col-span-9">
                                {this.state.succesMsg ? (
                                    <div
                                        className="
                                        bg-[#e6e7e8] px-6 py-6 rounded relative my-20"
                                        role="alert"
                                    >
                                        <strong className="font-bold font-pluto text-[#dc0032]">
                                            {t(
                                                'Bitten prüfen Sie Ihren E-Mail-Eingang'
                                            )}
                                        </strong>

                                        <button
                                            onClick={() =>
                                                this.setState({
                                                    succesMsg: false,
                                                })
                                            }
                                            className="absolute top-0 bottom-0 right-0 px-4 py-3"
                                        >
                                            <svg
                                                className="fill-current h-6 w-6 text-[#dc0032] cursor-pointer"
                                                role="button"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                            >
                                                <title>Close</title>
                                                <path
                                                    d="M14.348 14.849a1 1 0 01-1.414 0L10 11.414l-2.93 2.93a1 1 0 01-1.414-1.414l2.93-2.93-2.93-2.93a1 1 0 011.414-1.414l2.93 
                                                    2.93 2.93-2.93a1 1 0 011.414 1.414l-2.93 2.93 2.93 2.93a1 1 0 010 1.414z"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <h2 className="text-xl lg:text-2xl font-[600] mb-4">
                                            {
                                                this.state.allTextsData
                                                    .header_description
                                            }
                                        </h2>
                                        <div className="flex space-x-8">
                                            <div className="grid grid-cols-1 gap-6">
                                                <div className="flex flex-col lg:flex-row lg:items-center items-start lg:space-x-8  text-xl">
                                                    <div className="flex items-center space-x-2">
                                                        <div
                                                            id="1"
                                                            className="w-12 h-12 rounded-full
                                                flex items-center justify-center hover:bg-warm-grey cursor-pointer border-4 border-warm-grey transition duration-300 hover:bg-gray-300 hover:text-white"
                                                        >
                                                            1
                                                        </div>
                                                        <span
                                                            style={{
                                                                fontFamily:
                                                                    companyStyleinfo
                                                                        ?.data
                                                                        ?.fontType !==
                                                                    ''
                                                                        ? companyStyleinfo
                                                                              ?.data
                                                                              ?.fontType
                                                                        : 'sans-serif',
                                                                fontSize:
                                                                    companyStyleinfo
                                                                        ?.data
                                                                        ?.font_size +
                                                                    'px',
                                                                color: companyStyleinfo
                                                                    ?.data
                                                                    ?.font_color,
                                                                fontWeight:
                                                                    companyStyleinfo
                                                                        ?.data
                                                                        ?.isBold ===
                                                                    '1'
                                                                        ? 'bold'
                                                                        : 'normal',
                                                            }}
                                                        >
                                                            {t(
                                                                'Absenderdaten_eingeben'
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="flex items-center my-4">
                                                        <div
                                                            id="2"
                                                            className="lg:mx-2 w-12 h-12 rounded-full flex items-center justify-center hover:bg-warm-grey cursor-pointer border-4 border-warm-grey transition duration-300  hover:bg-gray-300 hover:text-white"
                                                        >
                                                            2
                                                        </div>
                                                        <span
                                                            style={{
                                                                fontFamily:
                                                                    companyStyleinfo
                                                                        ?.data
                                                                        ?.fontType !==
                                                                    ''
                                                                        ? companyStyleinfo
                                                                              ?.data
                                                                              ?.fontType
                                                                        : 'sans-serif',
                                                                fontSize:
                                                                    companyStyleinfo
                                                                        ?.data
                                                                        ?.font_size +
                                                                    'px',
                                                                color: companyStyleinfo
                                                                    ?.data
                                                                    ?.font_color,
                                                                fontWeight:
                                                                    companyStyleinfo
                                                                        ?.data
                                                                        ?.isBold ===
                                                                    '1'
                                                                        ? 'bold'
                                                                        : 'normal',
                                                            }}
                                                        >
                                                            {t(
                                                                'Retourenlabel_drucken'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>

                                                <label className="block">
                                                    <p
                                                        style={{
                                                            fontFamily:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.fontType !==
                                                                ''
                                                                    ? companyStyleinfo
                                                                          ?.data
                                                                          ?.fontType
                                                                    : 'sans-serif',
                                                            fontSize:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.font_size +
                                                                'px',
                                                            color: companyStyleinfo
                                                                ?.data
                                                                ?.font_color,
                                                            fontWeight:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.isBold ===
                                                                '1'
                                                                    ? 'bold'
                                                                    : 'normal',
                                                        }}
                                                    >
                                                        {t('order_number')}
                                                        {this.state
                                                            .requiredElementData[
                                                            'order_number'
                                                        ] === '1' ? (
                                                            <span> * </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </p>
                                                    <input
                                                        required={
                                                            this.state
                                                                .requiredElementData[
                                                                'order_number'
                                                            ]
                                                        }
                                                        value={this.state.refnr}
                                                        onChange={e => {
                                                            this.setState({
                                                                refnr: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                        autoComplete="off"
                                                        type="text"
                                                        name="refnr"
                                                        className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-mid-grey outline-none py-2"
                                                    />
                                                </label>

                                                <label className="block">
                                                    <p
                                                        style={{
                                                            fontFamily:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.fontType !==
                                                                ''
                                                                    ? companyStyleinfo
                                                                          ?.data
                                                                          ?.fontType
                                                                    : 'sans-serif',
                                                            fontSize:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.font_size +
                                                                'px',
                                                            color: companyStyleinfo
                                                                ?.data
                                                                ?.font_color,
                                                            fontWeight:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.isBold ===
                                                                '1'
                                                                    ? 'bold'
                                                                    : 'normal',
                                                        }}
                                                    >
                                                        {t('name')} *
                                                    </p>
                                                    <input
                                                        required={true}
                                                        value={this.state.name}
                                                        onChange={e => {
                                                            this.setState({
                                                                name: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                        autoComplete={'off'}
                                                        type="text"
                                                        name="name"
                                                        className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-mid-grey outline-none py-2"
                                                    />
                                                </label>

                                                <label className="block">
                                                    <p
                                                        style={{
                                                            fontFamily:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.fontType !==
                                                                ''
                                                                    ? companyStyleinfo
                                                                          ?.data
                                                                          ?.fontType
                                                                    : 'sans-serif',
                                                            fontSize:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.font_size +
                                                                'px',
                                                            color: companyStyleinfo
                                                                ?.data
                                                                ?.font_color,
                                                            fontWeight:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.isBold ===
                                                                '1'
                                                                    ? 'bold'
                                                                    : 'normal',
                                                        }}
                                                    >
                                                        {t('additive')}
                                                        {this.state
                                                            .requiredElementData[
                                                            'additive'
                                                        ] === '1' ? (
                                                            <span> * </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </p>
                                                    <input
                                                        required={
                                                            this.state
                                                                .requiredElementData[
                                                                'additive'
                                                            ]
                                                        }
                                                        value={
                                                            this.state.zusatz
                                                        }
                                                        onChange={e => {
                                                            this.setState({
                                                                zusatz: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                        autoComplete="off"
                                                        type="text"
                                                        name="zusatz"
                                                        className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-mid-grey outline-none py-2"
                                                    />
                                                </label>

                                                <label className="block">
                                                    <p
                                                        style={{
                                                            fontFamily:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.fontType !==
                                                                ''
                                                                    ? companyStyleinfo
                                                                          ?.data
                                                                          ?.fontType
                                                                    : 'sans-serif',
                                                            fontSize:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.font_size +
                                                                'px',
                                                            color: companyStyleinfo
                                                                ?.data
                                                                ?.font_color,
                                                            fontWeight:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.isBold ===
                                                                '1'
                                                                    ? 'bold'
                                                                    : 'normal',
                                                        }}
                                                    >
                                                        {t('street')} *
                                                    </p>
                                                    <input
                                                        required={true}
                                                        value={
                                                            this.state.strasse
                                                        }
                                                        onChange={e => {
                                                            this.setState({
                                                                strasse:
                                                                    e.target
                                                                        .value,
                                                            });
                                                        }}
                                                        autoComplete="off"
                                                        type="text"
                                                        name="strasse"
                                                        className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-mid-grey outline-none py-2"
                                                    />
                                                </label>

                                                <label className="block">
                                                    <p
                                                        style={{
                                                            fontFamily:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.fontType !==
                                                                ''
                                                                    ? companyStyleinfo
                                                                          ?.data
                                                                          ?.fontType
                                                                    : 'sans-serif',
                                                            fontSize:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.font_size +
                                                                'px',
                                                            color: companyStyleinfo
                                                                ?.data
                                                                ?.font_color,
                                                            fontWeight:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.isBold ===
                                                                '1'
                                                                    ? 'bold'
                                                                    : 'normal',
                                                        }}
                                                    >
                                                        {t('postcode')} *
                                                    </p>
                                                    <input
                                                        required={true}
                                                        value={this.state.plz}
                                                        onChange={e => {
                                                            this.setState({
                                                                plz: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                        autoComplete="off"
                                                        type="text"
                                                        name="plz"
                                                        className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-mid-grey outline-none py-2"
                                                    />
                                                </label>

                                                <label className="block">
                                                    <p
                                                        style={{
                                                            fontFamily:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.fontType !==
                                                                ''
                                                                    ? companyStyleinfo
                                                                          ?.data
                                                                          ?.fontType
                                                                    : 'sans-serif',
                                                            fontSize:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.font_size +
                                                                'px',
                                                            color: companyStyleinfo
                                                                ?.data
                                                                ?.font_color,
                                                            fontWeight:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.isBold ===
                                                                '1'
                                                                    ? 'bold'
                                                                    : 'normal',
                                                        }}
                                                    >
                                                        {t('city')} *
                                                    </p>
                                                    <input
                                                        required={true}
                                                        value={this.state.ort}
                                                        onChange={e => {
                                                            this.setState({
                                                                ort: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                        autoComplete="off"
                                                        type="text"
                                                        name="ort"
                                                        className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-mid-grey outline-none py-2"
                                                    />
                                                </label>

                                                <label className="block">
                                                    <p
                                                        style={{
                                                            fontFamily:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.fontType !==
                                                                ''
                                                                    ? companyStyleinfo
                                                                          ?.data
                                                                          ?.fontType
                                                                    : 'sans-serif',
                                                            fontSize:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.font_size +
                                                                'px',
                                                            color: companyStyleinfo
                                                                ?.data
                                                                ?.font_color,
                                                            fontWeight:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.isBold ===
                                                                '1'
                                                                    ? 'bold'
                                                                    : 'normal',
                                                        }}
                                                    >
                                                        {t('country')} *
                                                    </p>
                                                    {checkedCountries.length >
                                                    1 ? (
                                                        <select
                                                            required
                                                            value={land}
                                                            onChange={e => {
                                                                this.setState({
                                                                    land: e
                                                                        .target
                                                                        .value,
                                                                });
                                                            }}
                                                            className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-mid-grey outline-none py-2"
                                                        >
                                                            <option value="">
                                                                {t(
                                                                    'Select_country'
                                                                )}
                                                            </option>
                                                            {checkedCountries.map(
                                                                country => (
                                                                    <option
                                                                        key={
                                                                            country.country_id
                                                                        }
                                                                        value={
                                                                            country.short_name
                                                                        }
                                                                    >
                                                                        {
                                                                            country.country_name
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    ) : (
                                                        <input
                                                            readOnly
                                                            autoComplete="off"
                                                            type="text"
                                                            name="land"
                                                            className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-mid-grey outline-none py-2"
                                                            value={
                                                                checkedCountries.length ===
                                                                1
                                                                    ? checkedCountries[0]
                                                                          .country_name
                                                                    : ''
                                                            }
                                                            onChange={() => {
                                                                this.setState({
                                                                    land: checkedCountries[0]
                                                                        .short_name,
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                </label>

                                                <label className="block">
                                                    <p
                                                        style={{
                                                            fontFamily:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.fontType !==
                                                                ''
                                                                    ? companyStyleinfo
                                                                          ?.data
                                                                          ?.fontType
                                                                    : 'sans-serif',
                                                            fontSize:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.font_size +
                                                                'px',
                                                            color: companyStyleinfo
                                                                ?.data
                                                                ?.font_color,
                                                            fontWeight:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.isBold ===
                                                                '1'
                                                                    ? 'bold'
                                                                    : 'normal',
                                                        }}
                                                    >
                                                        {t('e-mail')} *
                                                    </p>
                                                    <input
                                                        required
                                                        value={this.state.email}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        autoComplete="off"
                                                        type="email"
                                                        name="email"
                                                        className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-mid-grey outline-none py-2"
                                                    />
                                                    {!this.state
                                                        .isEmailValid && (
                                                        <p className="text-red-500">
                                                            {
                                                                this.state
                                                                    .validationMessage
                                                            }
                                                        </p>
                                                    )}
                                                </label>

                                                <section>
                                                    <p
                                                        style={{
                                                            fontFamily:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.fontType !==
                                                                ''
                                                                    ? companyStyleinfo
                                                                          ?.data
                                                                          ?.fontType
                                                                    : 'sans-serif',
                                                            fontSize:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.font_size +
                                                                'px',
                                                            color: companyStyleinfo
                                                                ?.data
                                                                ?.font_color,
                                                            fontWeight:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.isBold ===
                                                                '1'
                                                                    ? 'bold'
                                                                    : 'normal',
                                                        }}
                                                    >
                                                        {
                                                            this.state
                                                                .allTextsData
                                                                .footer_titel
                                                        }
                                                    </p>
                                                    <br />
                                                    <p
                                                        style={{
                                                            fontFamily:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.fontType !==
                                                                ''
                                                                    ? companyStyleinfo
                                                                          ?.data
                                                                          ?.fontType
                                                                    : 'sans-serif',
                                                            fontSize:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.font_size +
                                                                'px',
                                                            color: companyStyleinfo
                                                                ?.data
                                                                ?.font_color,
                                                            fontWeight:
                                                                companyStyleinfo
                                                                    ?.data
                                                                    ?.isBold ===
                                                                '1'
                                                                    ? 'bold'
                                                                    : 'normal',
                                                        }}
                                                    >
                                                        {
                                                            this.state
                                                                .allTextsData
                                                                .footer_description
                                                        }
                                                    </p>
                                                </section>
                                                <div
                                                    className={`${
                                                        companyStyleinfo?.data
                                                            ?.Show_link === '0'
                                                            ? 'hidden '
                                                            : ''
                                                    }`}
                                                >
                                                    <span>Link :</span>{' '}
                                                    <a
                                                        className="text-blue-500 hover:text-blue-800 underline transition duration-300 ease-in-out ml-2
                            "
                                                        href="https://www.mydpd.at/shopfinder"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Pickupfinder
                                                    </a>
                                                </div>

                                                <div className="mb-4 w-full text-right disabled:opacity-50 disabled:cursor-not-allowed transition duration-300 ease-in-out mt-8 lg:mt-0 lg:w-auto lg:ml-auto lg:mr-0 mr-auto">
                                                    <button
                                                        disabled={
                                                            !this.state
                                                                .isEmailValid ||
                                                            this.state.email ===
                                                                '' ||
                                                            (this.state
                                                                .requiredElementData[
                                                                'order_number'
                                                            ] === '1' &&
                                                                this.state
                                                                    .refnr ===
                                                                    '') ||
                                                            (this.state
                                                                .requiredElementData[
                                                                'additive'
                                                            ] === '1' &&
                                                                this.state
                                                                    .zusatz ===
                                                                    '') ||
                                                            this.state.name ===
                                                                '' ||
                                                            this.state
                                                                .strasse ===
                                                                '' ||
                                                            this.state.plz ===
                                                                '' ||
                                                            this.state.land ===
                                                                '' ||
                                                            this.state.ort ===
                                                                ''
                                                        }
                                                        onClick={() => {
                                                            this.sendEmail();
                                                        }}
                                                        type="submit"
                                                        className="bg-[#dc0032] hover:bg-warm-grey text-white font-bold py-2 px-4 rounded-lg
                                                                            hover:opacity-80 abled:opacity-50 disabled:cursor-not-allowed transition duration-300 ease-in-out
                                            "
                                                        aria-label="Formular absenden"
                                                    >
                                                        {t('Send')}
                                                        <span className="text-lg font-bold">
                                                            →
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default withTranslation()(Retourenlabel);
