import axios from 'axios';
import * as React from 'react';
import store from '../../../Store';
import { Navigate } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface IHomedashProps  extends WithTranslation {}

export interface IHomedashState {
    selectedCustomer: string;
    admin_id:string;
    customerNames:
    {
        id: string;
        name: string;
        description: string;
        admin_id: string;
    }[]
    error: string
    zugewiesenerMandantList:any;


    

}

class Homedash extends React.Component<IHomedashProps, IHomedashState> {
  constructor(props: IHomedashProps) {
    super(props);
    this.state = {
        selectedCustomer:store.getSelectedCustomerDash()? store.getSelectedCustomerDash() : '',
        admin_id:  store.getAdminId(),
        customerNames: store.getCustomerNames(),
        error: '',
        zugewiesenerMandantList:[]

      };

    }
    private fetchZugewiesenerMandantList() : void  {

  
      let url ="https://returnmyparcel.at/select-Mandant.php/?u_id=" +sessionStorage.getItem("user.id");
      fetch(url) 
      .then(res => res.json())
      .then(data => {
        this.setState({ zugewiesenerMandantList: data });
        store.setCustomerNames(data)
        sessionStorage.setItem('customerNames', data);
      })
      }
    

     X: number = 62946;
     Y: number = 829;
    
    /**
     * Obfuscate the client number.
     *
     * @param clientNumber The client number to obfuscate.
     * @returns Returns the obfuscated number as a base64 encoded string.
     */
    private  codeClientNumber(clientNumber: number): string {
      const obfuscatedNumber: number = (clientNumber + this.X) * this.Y;
      return btoa(String(obfuscatedNumber)); // Using btoa for base64 encoding in browsers
    }

  componentDidMount() {
    this.fetchZugewiesenerMandantList();

  //  this.getCompaniesByAdminId()
  }
  componentDidUpdate(prevProps: Readonly<IHomedashProps>, prevState: Readonly<IHomedashState>, snapshot?: any): void {
  if (this.state.selectedCustomer!==prevState.selectedCustomer){
    store.setSelectedCustomerDash(this.state.selectedCustomer)
  }
}
      
      
    render() {
      // sessionStorage.getItem("user.id")?null:window.location.href = "http://localhost:3000/login";
      if (sessionStorage.getItem("user.id")===null){
        return (
          <Navigate 
          to="/login"
           replace={true}/>
        )
      }
      const { t } = this.props;
      const { selectedCustomer } = this.state;
      return (
          <div className="shadow-lg rounded-lg p-6 m-12 mx-auto w-full lg:w-2/3 h-full bg-gray-50 ">
              <div className="mb-4">
                  <label
                      className="block text-gray-900 text-sn lg:text-lg font-bold mb-2 "
                      htmlFor="customerDropdown"
                  >
                      {t('WÄHLEN SIE IHREN MANDANTEN AUS')}
                  </label>
                  <select
                      id="customerDropdown"
                      value={
                          sessionStorage?.getItem('selectedCustomerDashId') ||
                          selectedCustomer
                      }
                      onChange={e => {
                          store.setSelectedCustomerDash(
                              String(e.target.selectedOptions[0].text)
                          );
                          sessionStorage.setItem(
                              'selectedCustomerDashId',
                              String(e.target.value)
                          );
                          sessionStorage.setItem(
                              'selectedCustomerDashName',
                              String(e.target.selectedOptions[0].text)
                          );
                          sessionStorage.setItem(
                              'codeClientNumber',
                              String(
                                  this.codeClientNumber(Number(e.target.value))
                              )
                          );

                          this.setState({ selectedCustomer: e.target.value });
                      }}
                      className="shadow appearance-none border rounded w-2/3 lg:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                      <option value="">{t('Einen Mandanten auswählen')}</option>
                      {store.getCustomerNames()?.map((customerName: any) => (
                          <option
                              key={customerName.id + customerName.name}
                              value={customerName.id}
                          >
                              {customerName.name}
                          </option>
                      ))}
                  </select>
              </div>
          </div>
      );
    }
  }
  export default withTranslation()(Homedash);
