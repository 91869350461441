import * as React from 'react';
import { Link } from 'react-router-dom';
import LanguageSelector from '../../LanguageSelector';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface IFooterProps extends WithTranslation {
}

export interface IFooterState {}

class Footer extends React.Component<IFooterProps, IFooterState> {
    constructor(props: IFooterProps) {
        super(props);

        this.state = {};
    }

    public render() {
      const { t } = this.props;

        return (
            <div className="">
                <footer className="bg-transparent text-xs px-16 tracking-wide relative">
                    <div className="flex justify-between items-center max-lg:flex-col text-center flex-wrap gap-4">
                        <p className="text-[11px] leading-loose">
                            ©(1.20) {new Date().getFullYear()} DPD
                        </p>

                        <ul className="flex space-x-2 gap-y-2 max-lg:justify-center flex-wrap">
                            <li>
                                <Link
                                    to="/Impressum"
                                    target="_blank"
                                    className="text-[11px] hover:text-red-600 font-pluto"
                                >
                                  {t('Impressum')}
                                </Link>
                                <span className="pl-2  text-gray-400">|</span>
                            </li>
                            <li>
                                <a
                                    href="https://www.dpd.com/at/de/agb/"
                                    target="_blank"
                                    rel='noreferrer'
                                    className="text-[11px] hover:text-red-600 font-pluto"
                                >
                                    {t('AGB')}
                                </a>
                                <span className="pl-2  text-gray-400">|</span>
                            </li>
                            <li>
                                <a
                                    href="https://www.dpd.com/at/de/datenschutz/"
                                    target="_blank"
                                    rel='noreferrer'
                                    className="text-[11px] hover:text-red-600 font-pluto"
                                >
                                  {t('Datenschutz')}
                                </a>
                                <span className="pl-2  text-gray-400">|</span>
                            </li>
                            <li>
                                <a
                                    href="https://www.dpd.at/"
                                    target="_blank"
                                    rel='noreferrer'
                                    className="text-[11px] hover:text-red-600 font-pluto"
                                >
                                dpd.at
                                </a>
                                <span className="pl-2  text-gray-400">|</span>
                            </li>
                            <li>
                            <LanguageSelector />

                            </li>
                        </ul>
                    </div>
                </footer>
            </div>
        );
    }
}
export default withTranslation()(Footer);
