import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import store from '../../Store';
import Logo from '../../image/logo.png';

import { withTranslation, WithTranslation } from 'react-i18next';

export interface IHeaderProps extends WithTranslation {}

interface HeaderState {
    isNavbarTogglerActive: boolean;
    selectedCustomerDashName: string | null;
    subMenuActive: boolean;
}

class Header extends Component<IHeaderProps, HeaderState> {
    constructor(props: IHeaderProps) {
        super(props);
        this.state = {
            isNavbarTogglerActive: false,
            selectedCustomerDashName:
                sessionStorage?.getItem('selectedCustomerDashName') ||
                'Einen Mandanten auswählen',
            subMenuActive: false,
        };
        this.handleNavbarToggle = this.handleNavbarToggle.bind(this);
        this.handleNavItemClick = this.handleNavItemClick.bind(this);
        this.handleSubmenuClick = this.handleSubmenuClick.bind(this);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
    }

    handleNavbarToggle() {
        this.setState(prevState => ({
            isNavbarTogglerActive: !prevState.isNavbarTogglerActive,
        }));
    }

    handleNavItemClick() {
        this.setState({
            isNavbarTogglerActive: false,
        });
    }

    handleSubmenuClick(event: React.MouseEvent) {
        event.preventDefault();
        this.setState(prevState => ({
            subMenuActive: !prevState.subMenuActive,
        }));
    }

    componentDidUpdate(
        prevProps: Readonly<{}>,
        prevState: Readonly<HeaderState>
    ): void {
        const sessionCustomerDashName = sessionStorage.getItem(
            'selectedCustomerDashName'
        );
        if (
            sessionCustomerDashName !== prevState.selectedCustomerDashName &&
            sessionCustomerDashName
        ) {
            this.setState({
                selectedCustomerDashName: sessionCustomerDashName || '',
            });
        }
    }

    handleDocumentClick(event: MouseEvent) {
        const navbar = document.getElementById('navbarCollapse');
        const navbarToggler = document.getElementById('toggleOpen');

        if (
            navbar &&
            navbarToggler &&
            !navbar.contains(event.target as Node) &&
            !navbarToggler.contains(event.target as Node)
        ) {
            this.setState({
                isNavbarTogglerActive: false,
                subMenuActive: false,
            });
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDocumentClick);
    }

    render() {
        const { t } = this.props;
        const {
            subMenuActive,
            isNavbarTogglerActive,
            selectedCustomerDashName,
        } = this.state;

        return (
            <header
                id="navbarCollapse"
                className="flex shadow-md py-1 px-4 sm:px-10 bg-white font-sans min-h-[75px] tracking-wide relative z-50"
            >
                <div className="flex flex-wrap items-center gap-4 w-full bg-white">
                    <button>
                        <img src={Logo} alt="logo" className="w-36" />
                    </button>

                    <div
                        id="collapseMenu"
                        className={`absolute top-0 left-0 bg-white w-full h-full shadow-md py-4 px-4 sm:px-10 z-50
            transform transition-transform duration-300 ease-in-out 
            ${
                isNavbarTogglerActive
                    ? 'translate-x-0'
                    : '-translate-x-full lg:translate-x-0'
            }`}
                    >
                        <ul className="lg:flex lg:gap-x-4 max-lg:space-y-3 z-50 bg-white items-center">
                            {/* Removed the logo from the menu */}
                            <NavLink
                                to="/admin/"
                                onClick={this.handleNavItemClick}
                            >
                                <li className="max-lg:border-b  px-3 hidden lg:block">
                                    <button className="hover:text-[#dc0032] text-[#808285] block  text-base">
                                        <img
                                            src={Logo}
                                            alt="logo"
                                            className="w-20"
                                        />
                                    </button>
                                </li>
                            </NavLink>
                            <NavLink
                                to="/admin/"
                                onClick={this.handleNavItemClick}
                            >
                                <li className="max-lg:border-b  px-3">
                                    <button className="text-[#dc0032] text-base font-pluto">
                                        <span className="ms-3">
                                            {t('Mandantenauswahl')}
                                            {selectedCustomerDashName && (
                                                <span className="text-[#dc0032] ml-1 font-pluto font-bold">
                                                    {selectedCustomerDashName !==
                                                    t(
                                                        'Einen Mandanten auswählen'
                                                    )
                                                        ? selectedCustomerDashName
                                                        : ''}
                                                </span>
                                            )}
                                        </span>
                                    </button>
                                </li>
                            </NavLink>
                            <NavLink
                                        to="/admin/SetLogo"
                                        onClick={this.handleNavItemClick}
                                    >
                            <button
                                disabled={
                                    selectedCustomerDashName ===
                                    t('Einen Mandanten auswählen')
                                }
                                className="hover:text-[#dc0032] text-[#808285] block  text-base
                                                disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:text-[#808285]"
                            >
                                <span className="ms-3 whitespace-nowrap font-pluto">
                                    Logo
                                </span>
                                    
                            </button>
                            </NavLink>
                            <NavLink
                                to="/admin/FontFamily"
                                onClick={this.handleNavItemClick}
                            >
                                    <button
                                        disabled={
                                            selectedCustomerDashName ===
                                            t('Einen Mandanten auswählen')
                                        }
                                        className="hover:text-[#dc0032] text-[#808285] block  text-base
                                                disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:text-[#808285]"
                                    >
                                        <span className="ms-3 whitespace-nowrap font-pluto">
                                            {t('Schriftstile')}
                                        </span>
                                    </button>
                            </NavLink>
                            <NavLink
                                to="/admin/TextManagement/"
                                onClick={this.handleNavItemClick}
                            >
                                    <button
                                        disabled={
                                            selectedCustomerDashName ===
                                            t('Einen Mandanten auswählen')
                                        }
                                        className="hover:text-[#dc0032] text-[#808285] block  text-base
                                                disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:text-[#808285]"
                                    >
                                        <span className="ms-3 whitespace-nowrap font-pluto">
                                            {t('Textmanagement')}
                                        </span>
                                    </button>
                            </NavLink>
                            <NavLink
                                to="/admin/RequiredElement/"
                                onClick={this.handleNavItemClick}
                            >
                                    <button
                                        disabled={
                                            selectedCustomerDashName ===
                                            t('Einen Mandanten auswählen')
                                        }
                                        className="hover:text-[#dc0032] text-[#808285] block  text-base
                                                disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:text-[#808285]"
                                    >
                                        <span className="ms-3 whitespace-nowrap font-pluto">
                                            {t('Optionen')}
                                        </span>
                                    </button>
                            </NavLink>

                            <NavLink
                                target="_blank"
                                to={`/erfassung/?m=${sessionStorage.getItem(
                                    'codeClientNumber'
                                )}`}
                                onClick={this.handleNavItemClick}
                            >
                                    <button
                                        disabled={
                                            selectedCustomerDashName ===
                                            t('Einen Mandanten auswählen')
                                        }
                                        className="hover:text-[#dc0032] text-[#808285] block  text-base
                                                disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:text-[#808285]"
                                    >
                                        <span className="ms-3 whitespace-nowrap font-pluto">
                                            {t('Zum Retourenlabel gehen')}
                                        </span>
                                    </button>
                            </NavLink>

                            {/* Logout button inside the menu for small screens */}
                            <li className="max-lg:border-b  px-3 lg:absolute lg:right-1 ">
                                <NavLink
                                    to="/login/"
                                    onClick={this.handleNavItemClick}
                                >
                                    <button
                                        onClick={() => {
                                            sessionStorage.clear();
                                            store.setSelectedCustomerDash('');
                                        }}
                                        className="bg-[#dc0032] hover:animate-pulse text-white font-pluto font-bold py-1 px-1 rounded focus:outline-none focus:shadow-outline text-sm
                    disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-[#e6e7e8] disabled:text-[#808285] "
                                    >
                                        <span className="ms-3 whitespace-nowrap font-pluto">
                                            {t('Abmelden')} ({' '}
                                            {sessionStorage.getItem('userName')}{' '}
                                            )
                                        </span>
                                    </button>
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    {/* Button to toggle menu on small screens */}
                    <div id="toggleOpen" className="flex ml-auto lg:hidden">
                        <button
                            className="ml-6"
                            onClick={this.handleNavbarToggle}
                        >
                            <svg
                                className="w-7 h-7"
                                fill="#000"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </header>
        );
    }
}

export default withTranslation()(Header);
