import * as React from "react";
import Header from "./Layaout/Header";
import Main from "./Layaout/Main";
import Footer from "./Layaout/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Retourenlabel from "./Pages/user/Retourenlabel";
import NewHeader from "./Layaout/NewHeader";

export interface IWebAppProps {}

export interface IWebAppState {
  pageName: string;
}

export default class WebApp extends React.Component<
  IWebAppProps,
  IWebAppState
> {
  constructor(props: IWebAppProps) {
    super(props);

    this.state = {
      pageName: "Home",
    };
  }



  public render() {
    return (
        <div>
            <div className="h-full">
                <div className="font-pluto">
                    <NewHeader />
                </div>
                <div className="mt-10 h-[80vh] font-pluto">
                    <Main />
                </div>
                <div className="">
                    <Footer />
                </div>
            </div>
        </div>
    );
  }
}


