import * as React from 'react';
import store from '../../../Store';
import { WithTranslation, withTranslation } from 'react-i18next';

const LanguageSelectorArr = [
    { code: 'de', name: 'Deutsch' },
    { code: 'en', name: 'English' },
];

export interface ITextManagementProps extends WithTranslation {}

export interface ITextManagementState {
    allTextsData: any; // Changed from any[] to any to store an object with fields
    language: string;
    msg: string;
    Show_link: string;
    isLoading: boolean;
    oldFooterTitle: string;
    oldFooterDescription: string;
    oldHeaderTitle: string;
    oldHeaderDescription: string;
}

class TextManagement extends React.Component<
    ITextManagementProps,
    ITextManagementState
> {
    constructor(props: ITextManagementProps) {
        super(props);

        this.state = {
            allTextsData: {}, // Initializing as an object
            language: 'de',
            msg: '',
            Show_link: '',
            isLoading: true,
            oldFooterTitle: '',
            oldFooterDescription: '',
            oldHeaderTitle: '',
            oldHeaderDescription: '',
        };
    }

    public componentDidUpdate(
        prevProps: Readonly<ITextManagementProps>,
        prevState: Readonly<ITextManagementState>,
        snapshot?: any
    ): void {
        if (prevState.language !== this.state.language) {
            this.getTextContentByLn();
        }
    }

    public componentDidMount(): void {
        this.getTextContentByLn();
        console.log('allTextsData:', this.state.allTextsData);
        if (this.state.allTextsData === null)
            this.createDefaultTextContent();

    }

    public getTextContentByLn = async () => {
        const company_name = sessionStorage.getItem('codeClientNumber');
        const bo = {
            action: 'gettextcontentbyln',
            language: this.state.language,
            company_name: company_name,
        };

        this.setState({ isLoading: true });

        try {
            const response = await fetch(
                'https://returnmyparcel.at/textmanagement.php',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(bo),
                }
            );
            const data = await response.json();
            console.log('Fetched data:', data);

            if (data.status === 'success')
            {
                if (data.data === null)
                {
                    console.error('No text data found, creating default content.');
                    this.createDefaultTextContent(); // Trigger default content creation
                }
                this.setState({
                    allTextsData: data.data[0], // Use the first data object if multiple
                    isLoading: false,
                    oldFooterTitle: data.data[0].footer_titel,
                    oldFooterDescription: data.data[0].footer_description,
                    oldHeaderTitle: data.data[0].header_titel,
                    oldHeaderDescription: data.data[0].header_description,
                });
            } else {
                console.error(
                    'No text data found for this company and language.'
                );
                this.setState({ isLoading: false });
            }
        } catch (error) {
            console.error('Error fetching text content:', error);
            this.setState({ isLoading: false });
        }
    };

    // Function to create default text content
    public createDefaultTextContent = async () => {
        const company_name = sessionStorage.getItem('codeClientNumber');
        const bodyRequest = {
            action: 'createtextcontent',
            company_name: company_name,
            header_titel:
                this.state.language === 'de'
                    ? 'Erstellung des RETURN Label'
                    : 'Creation of the RETURN label',
            footer_description:
                this.state.language === 'de'
                    ? 'Laden Sie das Retourenlabel als PDF herunter, drucken Sie es aus und kleben Sie es gut sichtbar auf die größte Fläche Ihres Retourpakets. Anschließend können Sie Ihr Retourpaket in einem DPD Pickup Paketshop abgeben.'
                    : 'Download the returns label as a PDF, print it out and stick it clearly visible on the largest surface of your return parcel. You can then hand in your return parcel at a DPD Pickup parcelshop.',

            footer_titel: this.state.language === 'de' ? 'Informationen zum Druck des Retourenlabels:' : 'Information about printing the return label:',
            header_description: '',
            language: this.state.language,
        };

        try {
            const response = await fetch(
                'https://returnmyparcel.at/textmanagement.php',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(bodyRequest),
                }
            );
            const data = await response.json();
            if (data.status === 'success') {
                console.log('Default text content created');
                this.getTextContentByLn(); // Fetch the newly created data
            } else {
                console.error(
                    'Error creating default text content: ',
                    data.message
                );
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    public handleLanguageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newLanguage = e.target.value;
        this.setState({ language: newLanguage });
        store.setLn(newLanguage);
        console.log('Language changed to: ', newLanguage);
    };

    public updateTextData = async (field: string, value: string) => {
        const company_name = sessionStorage.getItem('codeClientNumber');
        const bodyRequest = {
            action: 'updatetextcontent',
            id: this.state.allTextsData.id, // Ensure `id` exists in the fetched data
            company_name: company_name,
            language: this.state.language,
            [field]: value, // Dynamically set the updated field
        };

        console.log('Updating with data: ', bodyRequest); // Log request body

        try {
            const response = await fetch(
                'https://returnmyparcel.at/textmanagement.php',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(bodyRequest),
                }
            );
            const data = await response.json();
            if (data.status === 'success') {
                console.log('Text updated successfully');
                this.setState({ msg: 'Text updated successfully' });
                setTimeout(() => this.setState({ msg: '' }), 3000);
                this.getTextContentByLn(); // Refresh the data
            } else {
                console.error('Error updating text: ', data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

      savechange(): void {
        // Ensure that this.state is defined
        if (!this.state) {
          console.error("Component state is undefined");
          return;
        }
      
        const { Show_link } = this.state;
        const data = {
            Show_link,
          company_name: sessionStorage.getItem('codeClientNumber'),
        };
      
      
        sessionStorage.getItem('codeClientNumber') &&
          // Fetch the login API endpoint
          fetch('https://returnmyparcel.at/style_api.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.text())  // Change this line to get the response text
            .then((responseData) => {
        
                // Your existing code to handle the JSON response
                const parsedData = JSON.parse(responseData);
                if (parsedData.status === 'success') {
                } else {
                    console.error('Logo data retrieval failed:', parsedData);
                }
            })
            .catch((error) => {
                console.error('Error during fetch:', error);
            });
      }

    public render() {
        const { t } = this.props;
        const { allTextsData, isLoading } = this.state;

        return (
            <div className="container w-full mx-auto p-4 shadow-lg rounded-lg bg-white">
                {/* Language Selection */}
                <div className="flex flex-col items-center justify-center gap-2 p-4">
                    <label className="block text-lg font-medium text-slate-800 font-pluto">
                        {t('Select language')}
                    </label>
                    <div className="flex justify-center lg:justify-start gap-4">
                        {LanguageSelectorArr.map((lang, index) => (
                            <label
                                key={index}
                                className="flex items-center gap-2"
                            >
                                <input
                                    type="radio"
                                    name="language"
                                    value={lang.code}
                                    checked={this.state.language === lang.code}
                                    className="cursor-pointer"
                                    onChange={this.handleLanguageChange}
                                />
                                <span className="cursor-pointer font-pluto">
                                    {t(lang.name)}
                                </span>
                            </label>
                        ))}
                    </div>
                </div>

                {/* Loading Indicator */}
                {isLoading && (
                    <p className="text-center text-gray-500">Loading data...</p>
                )}

                {/* Text Fields */}
                {!isLoading && allTextsData && (
                    <div className="items-center justify-center w-1/2 mx-auto">
                        {this.state.msg && (
                            <div className="bg-[#e6e7e8] border  text-[#dc0032] px-4 py-3 rounded relative mt-4 font-pluto">
                                {t(this.state.msg)}
                            </div>
                        )}

                        {/* Footer Title */}
                        <div className="relative my-6 border border-[#e6e7e8]rounded-lg p-4 bg-white shadow-md transition-all hover:shadow-lg focus-within:shadow-lg focus-within:ring-2 focus-within:ring-[#dc0032] focus-within:ring-opacity-50 font-pluto">
                            <textarea
                                id="Footer_Title"
                                name="Footer_Title"
                                value={allTextsData.footer_titel || ''}
                                onChange={e =>
                                    this.setState({
                                        allTextsData: {
                                            ...allTextsData,
                                            footer_titel: e.target.value,
                                        },
                                    })
                                }
                                className="peer relative h-9 w-full rounded border border-[#e6e7e8] p-1 my-4 text-sm text-[#808285] placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-[#dc0032] focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                            />
                            <label
                                htmlFor="Footer_Title"
                                className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-[#dc0032] peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent font-pluto"
                            >
                                {t('Footer_Title')}
                            </label>
                            <button
                                className="absolute bottom-1 right-1 p-1 rounded-full bg-[#dc0032] hover:animate-pulse text-white font-pluto text-sm
                                            disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-[#e6e7e8] disabled:text-[#808285] "
                                disabled={
                                    allTextsData.footer_titel ===
                                    this.state.oldFooterTitle
                                }
                                onClick={() =>
                                    this.updateTextData(
                                        'footer_titel',
                                        allTextsData.footer_titel
                                    )
                                }
                            >
                                {t('Update')}
                            </button>
                        </div>

                        {/* Footer Description */}
                        <div className="relative my-6 border border-[#e6e7e8]rounded-lg p-4 bg-white shadow-md transition-all hover:shadow-lg focus-within:shadow-lg focus-within:ring-2 focus-within:ring-[#dc0032] focus-within:ring-opacity-50 font-pluto">
                            <textarea
                                id="Footer_Description"
                                name="Footer_Description"
                                value={allTextsData.footer_description || ''}
                                onChange={e =>
                                    this.setState({
                                        allTextsData: {
                                            ...allTextsData,
                                            footer_description: e.target.value,
                                        },
                                    })
                                }
                                className="peer relative h-9 w-full rounded border border-[#e6e7e8] p-1 my-4 text-sm text-[#808285] placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-[#dc0032] focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                            />
                            <label
                                htmlFor="Footer_Description"
                                className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-[#dc0032] peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent font-pluto"
                            >
                                {t('Footer_Description')}
                            </label>
                            <button
                                className="absolute bottom-1 right-1 p-1 rounded-full bg-[#dc0032] hover:animate-pulse text-white font-pluto text-sm
                                            disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-[#e6e7e8] disabled:text-[#808285] "
                                disabled={
                                    allTextsData.footer_description ===
                                    this.state.oldFooterDescription
                                }
                                onClick={() =>
                                    this.updateTextData(
                                        'footer_description',
                                        allTextsData.footer_description
                                    )
                                }
                            >
                                {t('Update')}
                            </button>
                        </div>
                        {/* Header Title */}
                        <div className="relative my-6 border border-[#e6e7e8]rounded-lg p-4 bg-white shadow-md transition-all hover:shadow-lg focus-within:shadow-lg focus-within:ring-2 focus-within:ring-[#dc0032] focus-within:ring-opacity-50 font-pluto">
                            <textarea
                                id="Header_Title"
                                name="Header_Title"
                                value={allTextsData.header_titel || ''}
                                onChange={e =>
                                    this.setState({
                                        allTextsData: {
                                            ...allTextsData,
                                            header_titel: e.target.value,
                                        },
                                    })
                                }
                                className="peer relative h-9 w-full rounded border border-[#e6e7e8] p-1 my-4 text-sm text-[#808285] placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-[#dc0032] focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                            />
                            <label
                                htmlFor="Header_Title"
                                className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-[#dc0032] peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent font-pluto"
                            >
                                {t('Header_Title')}
                            </label>
                            <button
                                className="absolute bottom-1 right-1 p-1 rounded-full bg-[#dc0032] hover:animate-pulse text-white font-pluto text-sm
                                            disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-[#e6e7e8] disabled:text
                                            -[#808285] "
                                disabled={
                                    allTextsData.header_titel ===
                                    this.state.oldHeaderTitle
                                }
                                onClick={() =>
                                    this.updateTextData(
                                        'header_titel',
                                        allTextsData.header_titel
                                    )
                                }
                            >
                                {t('Update')}
                            </button>
                        </div>

                        {/* Header Description */}
                        <div className="relative my-6 border border-[#e6e7e8]rounded-lg p-4 bg-white shadow-md transition-all hover:shadow-lg focus-within:shadow-lg focus-within:ring-2 focus-within:ring-[#dc0032] focus-within:ring-opacity-50 font-pluto">
                            <textarea
                                id="Header_Description"
                                name="Header_Description"
                                value={allTextsData.header_description || ''}
                                onChange={e =>
                                    this.setState({
                                        allTextsData: {
                                            ...allTextsData,
                                            header_description: e.target.value,
                                        },
                                    })
                                }
                                className="peer relative h-9 w-full rounded border border-[#e6e7e8] p-1 my-4 text-sm text-[#808285] placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-[#dc0032] focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                            />
                            <label
                                htmlFor="Header_Description"
                                className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-[#dc0032] peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent font-pluto"
                            >
                                {t('Header_Description')}
                            </label>
                            <button
                                className="absolute bottom-1 right-1 p-1 rounded-full bg-[#dc0032] hover:animate-pulse text-white font-pluto text-sm
                                            disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-[#e6e7e8] disabled:text
                                            -[#808285] "
                                disabled={
                                    allTextsData.header_description ===
                                    this.state.oldHeaderDescription
                                }
                                onClick={() =>
                                    this.updateTextData(
                                        'header_description',
                                        allTextsData.header_description
                                    )
                                }
                            >
                                {t('Update')}
                            </button>
                        </div>
                    </div>
                )}
                <div className="flex justify-center">
                    <div className="flex items-center gap-4 px-4 py-3">
                        <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                            <label
                                className="w-full text-base font-medium text-slate-800 truncate cursor-pointer hover:text-slate-900 font-pluto"
                                htmlFor={'checkbox'}
                            >
                                {t('Show Find Shop Link')}
                            </label>
                        </div>

                        <div className="relative flex flex-wrap items-center">
                            <input
                                className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-[#e6e7e8] transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-[#808285] after:transition-all checked:bg-red-200 checked:after:left-4 checked:after:bg-red-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-red-300 checked:after:hover:bg-red-600 focus:outline-none checked:focus:bg-red-400 checked:after:focus:bg-[#dc0032] focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-[#e6e7e8]disabled:after:bg-[#e6e7e8] font-pluto"
                                type="checkbox"
                                id={'checkbox'}
                                checked={
                                    this.state.Show_link !== '0' ? true : false
                                } // Adjust based on your data structure
                                onChange={e => {
                                    this.setState(
                                        {
                                            Show_link: e.target.checked
                                                ? '1'
                                                : '0',
                                        },
                                        () => {
                                            this.savechange();
                                        }
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(TextManagement);