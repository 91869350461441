import * as React from 'react';
import LoginImage from '../../../image/login_pic.jpg';
import Logo from '../../../image/logo.png';
import { ReactComponent as UserIcon } from '../../../image/user.svg';
import { ReactComponent as EyeOffIcon } from '../../../image/eeon.svg';
import { ReactComponent as EyeOnIcon } from '../../../image/eeoff.svg';
import { Base64 } from 'js-base64';
import { Navigate } from 'react-router-dom';
import Footer from '../../Layaout/Footer';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface ILoginProps extends WithTranslation {}

export interface ILoginState {
    isEyeOn: boolean;
    userName: string;
    password: string;
    data: any;
    isLoggedIn: boolean;
    msg: string;
    rememberMe: boolean;
}

class TestLogin extends React.Component<ILoginProps, ILoginState> {
    constructor(props: ILoginProps) {
        super(props);

        this.state = {
            isEyeOn: false,
            userName: '',
            password: '',
            isLoggedIn: false,
            data: {},
            msg: '',
            rememberMe: false,
        };
    }

    componentDidMount() {
        const savedUserName = localStorage.getItem('saveduserName');
        const savedPassword = localStorage.getItem('savedpassword');

        if (savedUserName && savedPassword) {
            this.setState({
                userName: savedUserName,
                password: savedPassword,
                rememberMe: true,
            });
        }
    }

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        this.setState({ [name]: value } as Pick<ILoginState, any>);
    };

    private handleLogin(): void {
        const url = 'https://returnmyparcel.at/login_test.php/';
        const encodedPassword = Base64.encode(this.state.password);

        const requestData = {
            userName: this.state.userName,
            password: encodedPassword,
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        };

        fetch(url, requestOptions)
            .then(res => res.json())
            .then(data => {
                this.setState({ data: data });
                if (data['status'] === 'success') {
                    sessionStorage.setItem('user.id', data['adminId']);
                    sessionStorage.setItem('userName', data['userName']);
                    sessionStorage.setItem('redirectToMain', 'done');

                    if (this.state.rememberMe) {
                        localStorage.setItem(
                            'saveduserName',
                            this.state.userName
                        );
                        localStorage.setItem(
                            'savedpassword',
                            this.state.password
                        );
                    } else {
                        localStorage.removeItem('saveduserName');
                        localStorage.removeItem('savedpassword');
                    }

                    this.setState({ isLoggedIn: true }, () => {
                        sessionStorage.setItem('isLogin', 'true');
                    });
                } else {
                    this.setState({ msg: data['message'] });
                    sessionStorage.setItem('isLogin', 'false');
                    sessionStorage.removeItem('user.id');
                    sessionStorage.removeItem('userName');
                }
            })
            .catch(error => {
                console.error('Login failed:', error);
                this.setState({
                    msg: 'An error occurred. Please try again later.',
                });
            });
    }

    public render() {
        const { t } = this.props;
        const { isEyeOn, userName, password, isLoggedIn, rememberMe } =
            this.state;
        if (isLoggedIn || sessionStorage.getItem('isLogin') === 'true') {
            return <Navigate to="/admin/" replace={true} />;
        } else {
            return (
                <div className="bg-[#e4e4e4] h-screen pt-4">
                    <section className="bg-[#e4e4e4]">
                        {/* Upper part: Two columns on larger screens */}
                        <div className="container m-auto sm:mb-3 lg:mb-6 px-4 sm:px-6 md:px-[88px]">
                            <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12 bg-white shadow-gray-300 drop-shadow-2xl py-4 lg:py-0">
                                {/* Left Side (Form) */}
                                <div className="col-span-4 lg:col-span-6 bg-white px-6 sm:px-10 lg:px-28">
                                    <div className="text-center pt-10 mt-6">
                                        <img
                                            src={Logo}
                                            alt="Company logo"
                                            className="w-32 sm:w-44 mx-auto"
                                        />
                                        <p className="font-pluto text-base sm:text-[22px] my-10 sm:mt-20 sm:mb-12 leading-6 sm:leading-6 font-light text-black text-left">
                                            {t(
                                                'Willkommen auf ReturnMyParcel.'
                                            )}
                                            <br />
                                            {t(
                                                'Bitte geben Sie Ihre Zugangsdaten ein.'
                                            )}
                                        </p>
                                    </div>

                                    <form className="space-y-6 text-center ">
                                        {/* Username Field */}
                                        <div className="relative">
                                            <input
                                                name="userName"
                                                type="text"
                                                required
                                                value={userName}
                                                className="w-full text-sm border border-gray-300 focus:border-gray-800 px-2 py-3"
                                                placeholder="Benutzername"
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                            <UserIcon
                                                fill="#969696"
                                                className="w-[18px] h-[18px] absolute right-2 top-1/2 transform -translate-y-1/2"
                                            />
                                        </div>

                                        {/* Password Field */}
                                        <div className="relative flex items-center">
                                            <input
                                                name="password"
                                                type={
                                                    isEyeOn
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                required
                                                className="text-sm border border-gray-300 focus:border-gray-800 px-2 py-3 w-full"
                                                placeholder="Passwort"
                                                value={password}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                            {isEyeOn ? (
                                                <EyeOnIcon
                                                    fill="#969696"
                                                    className="w-[18px] h-[18px] absolute right-2 cursor-pointer"
                                                    onClick={() =>
                                                        this.setState({
                                                            isEyeOn: false,
                                                        })
                                                    }
                                                />
                                            ) : (
                                                <EyeOffIcon
                                                    fill="#969696"
                                                    className="w-[18px] h-[18px] absolute right-2 cursor-pointer"
                                                    onClick={() =>
                                                        this.setState({
                                                            isEyeOn: true,
                                                        })
                                                    }
                                                />
                                            )}
                                        </div>

                                        {/* Remember Me */}
                                        <div className="flex items-center py-4">
                                            <input
                                                id="remember-me"
                                                name="rememberMe"
                                                type="checkbox"
                                                checked={rememberMe}
                                                onChange={() =>
                                                    this.setState(
                                                        prevState => ({
                                                            rememberMe:
                                                                !prevState.rememberMe,
                                                        })
                                                    )
                                                }
                                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                            />
                                            <label
                                                htmlFor="remember-me"
                                                className="ml-3 text-sm text-gray-800"
                                            >
                                                {t('Angemeldet Speichern')}
                                            </label>
                                        </div>

                                        {/* Submit Button */}
                                        <button
                                            type="button"
                                            onClick={() => this.handleLogin()}
                                            className="w-2/3 py-3 px-6 text-base text-white bg-[#DC0032] hover:bg-red-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed transition duration-300 ease-in-out"
                                            disabled={!userName || !password}
                                        >
                                            {t('Anmelden')}
                                        </button>
                                    </form>
                                </div>

                                {/* Right Side (Image) - Hidden on small screens */}
                                <div className="col-span-4 lg:col-span-6 hidden md:block">
                                    <img
                                        src={LoginImage}
                                        alt="Login visual"
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Lower part: Full-width on all screen sizes */}
                        <div className="container px-2 lg:px-6 m-auto">
                            <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
                                <div className="col-span-4 md:col-span-8 lg:col-span-12">
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
        }
    }
}

export default withTranslation()(TestLogin);
