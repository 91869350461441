import * as React from "react";
import "./App.css";
import WebApp from "./Elements/WebApp";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Retourenlabel from "./Elements/Pages/user/Retourenlabel";
import Login from "./Elements/Pages/user/Login";
import Impressum from "./Elements/Pages/user/Impressum";

export interface IMainAppProps {}

export interface IMainAppState {
  customerNames: any[];
  error: string;
}

export default class MainApp extends React.Component<
  IMainAppProps,
  IMainAppState
> {
  constructor(props: IMainAppProps) {
    super(props);

    this.state = {
      customerNames: [],
      error: "",
    };
  }
  //https://returnmyparcel.at/company_api.php
  //get all company

  private getCompanies(): void {
    const bo = {
      action: "getCompanies",
    };
    try {
      fetch("https://returnmyparcel.at/company_api.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bo),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            // Login successful, handle further actions (e.g., redirect, update state)
            this.setState({ customerNames: data.data });
          } else {
            // Login failed, update state to show error
            this.setState({ error: data.message });
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  }
  componentDidMount(): void {
    this.getCompanies();
  }

  public render() {
    return (
      <div>
        <Router>
          <Routes>
            {this.state.customerNames.map((customerName) => (
              <Route
                key={customerName.name}
                path={`/erfassung`}
                element={<Retourenlabel paramName={customerName.name} />} // Pass the parameter as a prop
              />
            ))}

            {/* Root route redirects to Retourenlabel */}

            {/* Login page */}
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route path="/Impressum" element={<Impressum />} />

            {/* Admin panel routes */}
            <Route path="/admin/*" element={<WebApp />} />
          </Routes>
        </Router>
      </div>
    );
  }
}
