import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import ScrollToTop from "../Section/ScrollToTop";
import SetLogo from "../Pages/admin/SetLogo";
import Retourenlabel from "../Pages/user/Retourenlabel";
import FontSizeChanger from "../Pages/admin/FontSizeChanger";
import ButtonStylerChanger from "../Pages/admin/ButtonStylerChanger";
import Titel from "../Pages/admin/Titel";
import Homedash from "../Pages/admin/Homedash";
import AddCustomer from "../Pages/admin/AddCustomer";
import RequiredElement from "../Pages/admin/RequiredElement";
import TextManagement from "../Pages/admin/TextManagement";

export interface IMainProps {
}

export interface IMainState {
}

export default class Main extends React.Component<IMainProps, IMainState> {
  constructor(props: IMainProps) {
    super(props);

    this.state = {
    };
  }


  componentDidUpdate(prevProps: Readonly<IMainProps>, prevState: Readonly<IMainState>, snapshot?: any): void {
    

  }

  public render() {
    return (
      <div >
      {/* ScrollToTop component */}
      <ScrollToTop />

      {/* Routes for different components */}
      <Routes>
        <Route path="/" element={<Homedash />} />
        <Route path="SetLogo" element={<SetLogo />} />
        <Route path="FontFamily" element={<FontSizeChanger />} />
        <Route path="Buttons" element={<ButtonStylerChanger />} />
        <Route path="Titel" element={<Titel />} />
        <Route path="AddCustomer" element={<AddCustomer />} />
        <Route path="RequiredElement" element={<RequiredElement />} />
        <Route path="TextManagement" element={<TextManagement />} />

      </Routes>
    </div>
    );
  }
}
