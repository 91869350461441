import * as React from 'react';
import store from '../../../Store';
import { CompanyInfo } from '../user/Retourenlabel';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface IFontSizeChangerProps  extends WithTranslation {}

export interface IFontSizeChangerState {
    fontSize: number;
    textColor: string;
    fontType: string;
    isBold: boolean;
    old_fontSize: number;
    old_textColor: string;
    old_fontType: string;
    old_isBold: boolean;
    companyStyleinfo: CompanyInfo | null;
    company_name: string;
    ischanged: boolean;
    msg: string;
}


class FontSizeChanger extends React.Component<IFontSizeChangerProps, IFontSizeChangerState> {
  constructor(props: IFontSizeChangerProps) {
    super(props);

    this.state = {
        fontSize: 16, // Initial font size
        textColor: '#dc0032', // Initial text color
        fontType: 'Arial, sans-serif',
        isBold: false,
        companyStyleinfo: null,
        company_name:
            sessionStorage.getItem('selectedCustomerDashName') === null
                ? String(sessionStorage.getItem('selectedCustomerDashName'))
                : '',
        ischanged: false,
        msg: '',
        old_textColor: '#dc0032', // Initial text color
        old_fontSize: 16, // Initial font size
        old_fontType: 'Arial, sans-serif',
        old_isBold: false,
    };
    
  }
  increaseFontSize = () => {
    this.setState((prevState) => ({
        // max 32
        fontSize: Math.min(prevState.fontSize + 2, 32),
        ischanged: true,
        msg:""

    }));
  };

  decreaseFontSize = () => {
    this.setState((prevState) => ({
      fontSize: Math.max(prevState.fontSize - 2, 10),
        ischanged: true,
        msg:""

    }));
  };

  changeTextColor = (color: string) => {
    this.setState({ textColor: color
        , ischanged: true,
    msg:""
 });
  };

  changeFontType = (font: string) => {
    this.setState({ fontType: font 
        , ischanged: true
        ,msg:""});
  };

  toggleBold = () => {
    this.setState((prevState) => ({
      isBold: !prevState.isBold,
        ischanged: true,
        msg:""

    }));
  };

  savechange(): void {
    // Ensure that this.state is defined
    if (!this.state) {
      console.error("Component state is undefined");
      return;
    }
  
    const { fontSize, textColor, fontType, isBold } = this.state;
    const data = {
      fontSize,
      textColor,
      fontType,
      isBold,
      company_name: sessionStorage.getItem('codeClientNumber'),
      previewText:""
    };
  
  
    sessionStorage.getItem('codeClientNumber') &&
      // Fetch the login API endpoint
      fetch('https://returnmyparcel.at/style_api.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.text())  // Change this line to get the response text
        .then((responseData) => {
    
            // Your existing code to handle the JSON response
            const parsedData = JSON.parse(responseData);
            if (parsedData.status === 'success')
            {
                console.log('Logo data retrieved successfully:', parsedData);
                this.setState({
                    msg: 'Schriftstil gespeichert',
                    old_fontSize: fontSize,
                    old_textColor: textColor,
                    old_fontType: fontType,
                    old_isBold: isBold,
                 });
                this.setState({ischanged:false});
            } else {
                console.error('Logo data retrieval failed:', parsedData);
            }
        })
        .catch((error) => {
            console.error('Error during fetch:', error);
        });
  }

  getCustomerStyle(company_name: any): void {
  
  // Fetch the login API endpoint
  fetch(`https://returnmyparcel.at/get_style.php?company_name=${company_name}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 'success') {
        // Logo data retrieved successfully, handle further actions
        this.setState ({ 
                        fontType: data.data.fontType,
                        textColor: data.data.font_color,
                        isBold: data.data.isBold,
                        fontSize: data.data.font_size,
                        companyStyleinfo: data.data,
                        old_fontSize: data.data.font_size,
                        old_fontType: data.data.fontType,
                        old_textColor: data.data.font_color,
                        old_isBold: data.data.isBold
                        
                        });
      } else {
        // Handle the case where logo data retrieval failed
        this.setState ({
          fontType: 'Arial, sans-serif',
          textColor: '#000000',
          isBold: false,
          fontSize: 16,
          companyStyleinfo:null
          });
      }
    })
    .catch((error) => {
      // Handle network errors or other exceptions
      console.error('Error during fetch:', error);
    });
}

  

  componentDidMount(): void {
    this.getCustomerStyle(String(sessionStorage.getItem('codeClientNumber')));


  }  

  componentDidUpdate(prevProps: Readonly<IFontSizeChangerProps>, prevState: Readonly<IFontSizeChangerState>, snapshot?: any): void {
      if (this.state.company_name !== prevState.company_name) {
          this.getCustomerStyle(sessionStorage.getItem('codeClientNumber'));
      }
      // hied msg after 3 seconds
      if (this.state.msg) {
          setTimeout(() => {
              this.setState({ msg: '' });
          }, 4000);
      }
  }
  

  public  render() {
    const { t } = this.props;
      const { fontSize, textColor, fontType, isBold, old_fontSize, old_textColor, old_fontType, old_isBold} = this.state;

      return (
          <div className="container w-full mx-auto p-4 ">
              <div className="container w-full mx-auto p-4 shadow-lg rounded-lg bg-white mt-16 h-full">
                  {/*<!-- Component: Five and Seven uneven columns layout --> */}
                  <section>
                      <div className="container px-6 m-auto">
                          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
                              <div className="col-span-7">
                                  <div className="mb-4">
                                      <div className="mb-2">
                                          <label
                                              className="text-black text-lg pr-16"
                                              htmlFor=""
                                          >
                                              <span className="text-black text-lg  text-center justify-center my-4 font-pluto">
                                                  {t('Schriftgröße')}
                                              </span>
                                          </label>
                                          <button
                                              className={` ${
                                                  fontSize < 32
                                                      ? 'bg-gray-500 '
                                                      : 'bg-gray-300 '
                                              } hover:bg-[#dc0032] item-center text-white  py-2 px-4 my-2 rounded mr-3 w-12 `}
                                              onClick={this.increaseFontSize}
                                          >
                                              <span>+</span>
                                          </button>
                                          <button
                                              className={`${
                                                  fontSize > 10
                                                      ? 'bg-gray-500 '
                                                      : 'bg-gray-300 '
                                              }  hover:bg-[#dc0032] text-white  py-2 px-4 my-2 rounded w-12`}
                                              onClick={this.decreaseFontSize}
                                          >
                                              <span>-</span>
                                          </button>
                                      </div>
                                      <label
                                          className="text-black text-lg  "
                                          htmlFor=""
                                      >
                                          <span className="text-black text-lg  text-center justify-center my-4  font-pluto pr-9">
                                              {t('Textfarbe')}
                                          </span>
                                      </label>
                                      <input
                                          type="color"
                                          value={textColor}
                                          onChange={e =>
                                              this.changeTextColor(
                                                  e.target.value
                                              )
                                          }
                                          className="mx-8 w-16 h-6"
                                      />
                                      <div className="my-1 py-4">
                                          <label
                                              className="text-black text-lg  pr-8"
                                              htmlFor=""
                                          >
                                              <span className="text-black text-lg  text-center justify-center font-pluto">
                                                  {t('Schriftart')}
                                              </span>
                                          </label>
                                          <select
                                              value={fontType}
                                              onChange={e =>
                                                  this.changeFontType(
                                                      e.target.value
                                                  )
                                              }
                                              className="mr-2 bg-white border border-gray-400 rounded px-2 py-1 font-pluto"
                                          >
                                              <option value="Pluto Sans">
                                                  Pluto Sans
                                              </option>
                                              <option value="Arial, sans-serif">
                                                  Arial
                                              </option>
                                              <option value="Times New Roman, serif">
                                                  Times New Roman
                                              </option>
                                              <option value="Courier New, monospace">
                                                  Courier New
                                              </option>
                                              <option value="Verdana, Geneva, sans-serif">
                                                  Verdana
                                              </option>
                                          </select>
                                      </div>
                                      <label
                                          className="text-black text-lg  pr-20"
                                          htmlFor="checkbox"
                                      >
                                          <span className="text-black text-lg  text-center justify-center font-pluto">
                                              {t(
                                                  'Fettschrift ein-/ausschalten'
                                              )}
                                          </span>
                                      </label>

                                      <input
                                          id="checkbox"
                                          className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-[#e6e7e8] transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-[#808285] after:transition-all checked:bg-red-200 checked:after:left-4 checked:after:bg-red-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-red-300 checked:after:hover:bg-red-600 focus:outline-none checked:focus:bg-red-400 checked:after:focus:bg-[#dc0032] focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-[#e6e7e8]disabled:after:bg-[#e6e7e8] font-pluto"
                                          type="checkbox"
                                          checked={this.state.isBold} // Adjust based on your data structure
                                          onChange={e => {
                                              this.setState({
                                                  isBold: e.target.checked,
                                                  ischanged: true,
                                              });
                                          }}
                                      />
                                  </div>
                              </div>
                              <div className="col-span-4 lg:col-span-5">
                                  <div className="lg:py-12 lg:my-12 py-6 my-6 bg-[#e6e7e8] mx-auto w-full border-2 border-[#808285] shadow-lg rounded-md p-4 items-center justify-center text-center">
                                      <p
                                          className={`text-lg ${
                                              isBold ? 'font-bold' : ''
                                          } `}
                                          style={{
                                              fontSize: `${fontSize}px`,
                                              color: textColor,
                                              fontFamily: fontType,
                                          }}
                                      >
                                          {t('Preview new text')}
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
                  {/*<!-- End Five and Seven uneven columns layout --> */}

                  {/**add byn save */}
                  {this.state.ischanged && (
                      <div className="text-left ml-6">
                          <button
                              className="bg-[#dc0032] hover:animate-pulse text-white font-pluto font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline
                    disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-[#e6e7e8] disabled:text-[#808285] "
                              onClick={() => this.savechange()}
                          >
                              {t('Schriftstil speichern')}
                          </button>
                      </div>
                  )}
                  {this.state.msg !== '' && (
                      <div className="text-center bg-[#e6e7e8] p-2 mt-4">
                          <p
                              className="text-[#dc0032] font-pluto font-bold text-lg first-letter: 
                        text-center justify-center my-4"
                          >
                              {t(this.state.msg)}
                          </p>
                      </div>
                  )}
              </div>
              <div className="lg:py-12 lg:my-12 py-6 my-6 bg-[#e6e7e8] mx-auto w-full border-2 border-[#808285] shadow-lg rounded-md p-4 items-center justify-center text-center">
                  <p
                      className={`text-lg ${old_isBold ? 'font-bold' : ''} `}
                      style={{
                          fontSize: `${old_fontSize}px`,
                          color: old_textColor,
                          fontFamily: old_fontType,
                      }}
                  >
                      {t('Preview old text')}
                  </p>
              </div>
          </div>
      );
  }
}

export default withTranslation()(FontSizeChanger);
