import React, { Component } from 'react';

class Impressum extends Component {
    render() {
        return (
            <div className="bg-gray-100 min-h-screen py-10 px-6">
                <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-md">
                    <h2 className="text-2xl font-bold mb-4">Impressum</h2>
                    <p className="mb-4">
                        <strong>Offenlegung nach § 25 Mediengesetz</strong>
                    </p>

                    <div className="mb-6">
                        <p>Gebrüder Weiss Paketdienst Gesellschaft mbH</p>
                        <p>Arbeitergasse 50</p>
                        <p>A-2333 Leopoldsdorf</p>
                        <p>Tel. +43 (0) 810 / 810 110</p>
                        <p>Fax: +43 (0) 59006 / 8690</p>
                        <p>
                            e-mail:{' '}
                            <a
                                href="mailto:it-kundenberatung@gwp.dpd.at"
                                className="text-blue-600 hover:underline"
                            >
                                it-kundenberatung@gwp.dpd.at
                            </a>
                        </p>
                    </div>

                    <div className="mb-6">
                        <p>Firmenbuch: Landesgericht Feldkirch FN 33745a</p>
                        <p>DVR 0841986</p>
                        <p>UID: ATU35761608</p>
                    </div>

                    <div className="mb-6">
                        <p>Geschäftsführer: Andreas Winkelmayer</p>
                    </div>

                    <div>
                        <p>Mitgliedschaften: Wirtschaftskammer Österreich</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Impressum;
