import * as React from 'react';

export interface ITitelProps {
}

export interface ITitelState {
    newText: string;
    previewText: string;
}

export default class Titel extends React.Component<ITitelProps, ITitelState> {
  constructor(props: ITitelProps) {
    super(props);

    this.state = {
        newText: '',
        previewText: '',
      };
    }
  
    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        newText: e.target.value,
      });
    };
  
 
    savechange(): void {
      // Ensure that this.state is defined
      if (!this.state) {
        console.error("Component state is undefined");
        return;
      }
    
      const { newText } = this.state;
      const data = {
        fontSize:'',
        textColor:'',
        fontType:'',
        isBold:'',
        company_name: sessionStorage.getItem('codeClientNumber'),
        previewText:newText
      };
    
    
      sessionStorage.getItem('codeClientNumber') &&
        // Fetch the login API endpoint
        fetch('https://returnmyparcel.at/style_api.php', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
          .then((response) => response.text())  // Change this line to get the response text
          .then((responseData) => {
      
              // Your existing code to handle the JSON response
              const parsedData = JSON.parse(responseData);
              if (parsedData.status === 'success') {
              } else {
                  console.error('Logo data retrieval failed:', parsedData);
              }
          })
          .catch((error) => {
              console.error('Error during fetch:', error);
          });
    }

  
    render() {
      const { newText, previewText } = this.state;
  
      return (
        <div className="justify-center items-center h-screen bg-white py-16 px-4 sm:px-6 lg:px-8">
          <div className="mb-4">
            <label className="
            block text-gray-700 text-sm font-bold mb-2" htmlFor="textInput">
            Neuen Titel eingeben:
            </label>
            <input
              type="text"
              id="textInput"
              value={newText}
              onChange={this.handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
  
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => this.savechange()}
          >
            Save Text
          </button>
  
          {previewText && (
            <div className="mt-4">
              <p className="text-lg font-bold mb-2">Preview:</p>
              <p className="text-xl p-4 rounded">{previewText}</p>
            </div>
          )}
        </div>
      );
    }
  }