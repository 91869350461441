import React, { Component, ChangeEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import store from '../../../Store';

interface ISetLogoProps extends WithTranslation {}

interface ISetLogoState {
    selectedImage: File | null;
    previewImage: string;
    message: string;
    newImage: boolean;
    company_name: string;
    noImage: boolean;
    isSetLogo: boolean;
}

class SetLogo extends Component<ISetLogoProps, ISetLogoState> {
    constructor(props: ISetLogoProps) {
        super(props);

        this.state = {
            selectedImage: null,
            previewImage: '',
            message: '',
            newImage: false,
            company_name: sessionStorage.getItem('codeClientNumber') || '0',
            noImage: false,
            isSetLogo: false,
        };
    }

    handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const selectedImage = files[0];

            // Validate file type
            const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!validImageTypes.includes(selectedImage.type)) {
                this.setState({
                    message: 'Only PNG, JPG, and JPEG formats are accepted',
                    selectedImage: null,
                });
                return;
            }

            // Check the file size limit (2MB)
            if (selectedImage.size > 2000000) {
                this.setState({
                    message: 'Image size should be less than 2MB',
                    selectedImage: null,
                });
                return;
            }

            // Create a temporary URL for the image
            const imageUrl = window.URL.createObjectURL(selectedImage);
            const img = new Image();
            img.src = imageUrl;

            img.onload = () => {
                // Check the image dimensions
                if (img.width < 200 || img.height < 200) {
                    this.setState({
                        message: 'Image size should be at least 200x200px',
                        selectedImage: null,
                    });
                    window.URL.revokeObjectURL(imageUrl);
                    return;
                }

                // Image is valid, update the state
                this.setState(
                    {
                        selectedImage,
                        previewImage: imageUrl,
                        message: '',
                        newImage: true,
                        noImage: false,
                        isSetLogo: false,
                    },
                    () => {
                        // Callback after state update, now upload the image
                        this.handleImageUpload();
                    }
                );
            };

            img.onerror = () => {
                this.setState({
                    message: 'Invalid image file',
                    selectedImage: null,
                });
                window.URL.revokeObjectURL(imageUrl);
            };
        }
    };

    handleImageUpload = () => {
        const { t } = this.props;
        const { selectedImage, company_name } = this.state;

        if (!selectedImage) {
            this.setState({ message: 'Please select another image' });
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result?.toString().split(',')[1] || '';
            const requestBody = { logo: base64String, company_name };

            fetch('https://returnmyparcel.at/logo_api.php', {
                method: 'POST',
                body: JSON.stringify(requestBody),
                headers: { 'Content-Type': 'application/json' },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status === 'success') {
                        this.setState({
                            message: t(
                                'Das Bild wurde erfolgreich hochgeladen'
                            ),
                            noImage: false,
                            isSetLogo: true,
                            newImage: false,
                        });
                    } else {
                        this.setState({
                            message: t('Bild-Upload fehlgeschlagen'),
                            noImage: true,
                        });
                    }
                })
                .catch(error => {
                    console.error('Error uploading image:', error);
                    this.setState({
                        message: t('Bild-Upload fehlgeschlagen'),
                        noImage: true,
                    });
                });
        };
        reader.readAsDataURL(selectedImage!);
    };

    getCustomerLogo = (company_name: string) => {
        if (company_name !== '0') {
            fetch(
                `https://returnmyparcel.at/get_logo_api.php?company_name=${company_name}`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                }
            )
                .then(response => response.json())
                .then(data => {
                    if (data.status === 'success') {
                        this.setState({
                            previewImage: `data:image/png;base64,${data.data[0].image_content}`,
                            isSetLogo: true,
                        });
                    } else {
                        this.setState({
                            noImage: true,
                            isSetLogo: false,
                            previewImage: '',
                        });
                    }
                })
                .catch(error => {
                    console.error('Error fetching logo:', error);
                    this.setState({ noImage: true });
                });
        } else {
            this.setState({ noImage: true });
        }
    };

    componentDidMount() {
        this.getCustomerLogo(this.state.company_name);
    }

    componentDidUpdate(prevProps: ISetLogoProps, prevState: ISetLogoState) {
        if (this.state.company_name !== prevState.company_name) {
            this.getCustomerLogo(this.state.company_name);
        }

        if (this.state.message) {
            setTimeout(() => {
                this.setState({ message: '' });
            }, 5000);
        }
    }

    deleteLogo = () => {
        const { t } = this.props;
        const { company_name } = this.state;

        fetch('https://returnmyparcel.at/logo_api.php', {
            method: 'DELETE',
            body: JSON.stringify({ company_name }),
            headers: { 'Content-Type': 'application/json' },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    this.setState({
                        message: t('Bild erfolgreich gelöscht'),
                        noImage: true,
                        isSetLogo: false,
                        previewImage: '',
                        selectedImage: null,
                    });
                } else {
                    this.setState({
                        message: t('Failed to delete image'),
                        noImage: true,
                    });
                }
            })
            .catch(error => {
                console.error('Error deleting image:', error);
                this.setState({
                    message: t('Failed to delete image'),
                    noImage: true,
                });
            });
    };

    render() {
        const { t } = this.props;
        const { previewImage, message, noImage } = this.state;

        return (
            <div className="container w-full mx-auto p-6 shadow-lg rounded-lg bg-white mt-16">
                <p className="text-gray-900 px-12 font-pluto font-semibold text-center text-lg ">
                    {t(
                        'Hier können Sie Ihr Firmenlogo hochladen, welches auf der Seite dargestellt werden soll.'
                    )}
                </p>
                {!noImage ? (
                    previewImage && (
                        <div className="w-[90%] lg:w-1/4 h-1/4 mx-auto">
                            <img
                                src={previewImage}
                                alt=""
                                className="mx-auto"
                            />
                        </div>
                    )
                ) : (
                    <div className="w-1/3 h-1/3 mx-auto">
                        <p className="text-[#808285] p-12 mb-3 font-pluto font-semibold text-center text-lg">
                            {t('kein Logo vorhanden')}
                        </p>
                    </div>
                )}

                <label
                    htmlFor="file"
                    className="bg-[#dc0032] hover:animate-pulse text-white font-pluto font-bold py-2.5 px-4 rounded 
          focus:outline-none focus:shadow-outline mx-2"
                >
                    {this.state.isSetLogo
                        ? t('Logo ändern')
                        : t('Logo hochladen')}
                </label>

                <input
                    id="file"
                    className="hidden"
                    type="file"
                    lang={
                        sessionStorage.getItem('selectedLanguage') ||
                        store.getLn() ||
                        'de'
                    }
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={this.handleImageChange}
                    style={{ marginBottom: '10px' }}
                />

                {this.state.isSetLogo && (
                    <button
                        className="bg-[#dc0032] hover:animate-pulse text-white font-pluto font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline
            disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-[#e6e7e8] disabled:text-[#808285] "
                        onClick={this.deleteLogo}
                    >
                        {t('logo entfernen')}
                    </button>
                )}

                {message && (
                    <div className="text-center bg-[#e6e7e8] p-2 mt-4">
                        <p className="text-[#dc0032] font-pluto font-bold text-lg first-letter:text-center justify-center my-4">
                            {t(message)}
                        </p>
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(SetLogo);
