import axios from 'axios';
import * as React from 'react';
import store from '../../../Store';
import { Navigate } from 'react-router-dom';

export interface IAddCustomerProps {
}

export interface IAddCustomerState {
    name: string;
    description: string;
    admin_id: string;
    customerNames:
    {
        id: string;
        name: string;
        description: string;
        admin_id: string;
    }[];
    error: string
}

export default class AddCustomer extends React.Component<IAddCustomerProps, IAddCustomerState> {
  constructor(props: IAddCustomerProps) {
    super(props);

    this.state = {
        name: '',
        description: '',
        admin_id:  store.getAdminId(),
        customerNames: store.getCustomerNames(),
        error: '',
      };
      this.getCompaniesByAdminId();
    }
  

  
    handleAddCompany = () => {
      const { name, description, admin_id } = this.state;

      axios.post('https://returnmyparcel.at/company_api.php', {
        action: 'createCompany',
        name,
        description,
        admin_id:Number(admin_id),
      })
        .then(response => {
          // Optionally, you can redirect to a new page or update the state to reflect the changes
        })
        .catch(error => {
          console.error('Error:', error);
        });
        this.setState
        ({name: '',
        description: '',});
        this.getCompaniesByAdminId();
    };
    
    getCompaniesByAdminId() {
      const bo = {
           admin_id: sessionStorage.getItem('user.id'), 
           action: 'getCompaniesByAdminId',
      };
      try {
        fetch('https://returnmyparcel.at/company_api.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bo) ,  
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 'success') {
              // Login successful, handle further actions (e.g., redirect, update state)
              this.setState({ customerNames: data.data });
              store.setCustomerNames(data.data)
              sessionStorage.setItem('customerNames', data.data);
            } else {
              // Login failed, update state to show error
              this.setState({ error: data.message });
    
            }
          })
          .catch((error) => {
            console.error('Login Error:', error);
          });
      } catch (error) {
      }
    }
  
   
    render() {
      const { name, description } = this.state;
  
      return (
        <div className="w-full">
        <div className="mt-8 p-6 h-full mb-10 lg:shadow-2xl bg-white rounded-2xl">
          <h1 className="text-3xl font-bold mb-8">Eine neue Firma hinzufügen</h1>
  
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="companyName">
            Firmenname:
            </label>
            <input
              type="text"
              id="companyName"
              name="name"
              value={name}
              onChange={(e)=>{
                this.setState({name: e.target.value })
                }}
                
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
  
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="companyDescription">
            Firmenbeschreibung:
            </label>
            <textarea
              id="companyDescription"
              name="description"
              value={description}
              onChange={
                (e)=>{
                  this.setState({description: e.target.value })
                }
              }
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
  
          <button
          disabled={!name || !description}
            className="bg-green-700 hover:bg-green-600 text-white font-bold py-2 px-4 rounded
            focus:outline-none focus:shadow-outline
            disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={this.handleAddCompany}
          >
            Firma hinzufügen
          </button>
        </div>
        {/* customerNames Table */}
        <div className=" w-full mx-12 mt-8 py-6">
        <h1 className="text-3xl font-bold mb-8">Firmen Liste</h1>
        <table className="w-2/3 table pb-10 border-separate
          border border-black-800
          shadow-lg bg-white
          rounded-lg
          overflow-hidden
          mx-auto
          mb-5
          mt-5
          text-sm
        ">
          <thead>
            <tr className='
              bg-gray-900
              text-gray-200
              
              text-sm
              h-12
              leading-normal
              border-gray-300

            '>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Beschreibung</th>
              <th className="px-4 py-2">Admin ID</th>
            </tr>
          </thead>
          <tbody>
            {this.state.customerNames.map( (customerName) => (
              <tr className='
                bg-white
                text-black
                text-center
                text-base
                border-gray-300
                border
                hover:bg-red-200
                hover:text-gray-800
                h-8 
                leading-normal
              '  
              onClick={()=>
                { <Navigate to={`/${customerName.name}`} replace={true} />}
                
              }
              key={customerName.id+customerName.name}>
                <td className="border px-4 py-2">{customerName.name}</td>
                <td className="border px-4 py-2">{customerName.description}</td>
                <td className="border px-4 py-2">{customerName.admin_id}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        </div>
      );
    }
  }